@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap");

/* body {
  font-family: "JetBrains Mono", monospace;
} */
html {
  font-size: 1rem;
}

.layoutflow {
  flex-grow: 1;
  position: relative;
  height: 95vh;
  z-index: 0;
}

.layoutflow :global .react-flow__node-default,
.layoutflow :global .react-flow__edge-text {
  font-size: 0.6rem;
}


/* if edge type not floating, have to comment this out */
.layoutflow :global .react-flow__handle {
  display: none;
}

.layoutflow :global .react-flow__node {
  /* width: 300px !important; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.layoutflow :global .react-flow__attribution {
  display: none;
}
