.UserBriefDetails {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;
    padding-right: 16px;
}

.MenuBarEnd {
    display: flex;
    flex-direction: row;
    padding-right: 16px;
}

.MenuBarStart {
    display: flex;
    flex-direction: row;
    padding-right: 16px;
}