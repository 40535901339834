/* DataViewDemo.css */

.myDataview :global .p-dropdown {
  width: 14rem;
  font-weight: normal;
}

.myDataview :global .product-name {
  font-size: 1rem;
  font-weight: 500;
}

.myDataview :global .product-description {
  margin: 0 0 1rem 0;
}

.myDataview :global .product-category-icon {
  vertical-align: middle;
  margin-right: .5rem;
}

.myDataview :global .product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.myDataview :global .p-dataview-content .p-grid {
  display: flex;
  flex-wrap: wrap;
}

.myDataview :global .product-list-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}

.myDataview :global .product-list-item .product-list-detail {
  flex: 1 1 0;
}

.myDataview :global .product-list-item .p-rating {
  margin: 0 0 .5rem 0;
}

.myDataview :global .product-list-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: .5rem;
  align-self: flex-end;
}

.myDataview :global .product-list-item .product-list-action {
  display: flex;
  flex-direction: column;
}

.myDataview :global .product-list-item .p-button {
  margin-bottom: .5rem;
}

.myDataview :global .product-grid-item {
  margin: .5em;
  border: 1px solid var(--surface-border);
  padding: 1.5rem;
}

.myDataview :global .product-grid-item .product-grid-item-top,
.myDataview :global .product-grid-item .product-grid-item-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.myDataview :global .product-grid-item img {
  width: 75%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 2rem 0;
}

.myDataview :global .product-grid-item .product-grid-item-content {
  text-align: center;
}

@media screen and (max-width: 576px) {
  .myDataview :global .product-list-item {
    flex-direction: column;
    align-items: center;
  }

  .myDataview :global .product-list-item img {
    width: 75%;
    margin: 2rem 0;
  }

  .myDataview :global .product-list-item .product-list-detail {
    text-align: center;
  }

  .myDataview :global .product-list-item .product-price {
    align-self: center;
  }

  .myDataview :global .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .myDataview :global .product-list-item .product-list-action {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}