.Email {
    font-size: 16px
}

.Container {
    padding-top: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SubmitButton {
    margin-top: 32px
}

.ResetPasswordButton {
    margin-top: 8px
}

.p-inputtext {
    width: 100% !important
}
  