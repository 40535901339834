body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* changing of secondary button colour */
.p-button.p-button-secondary, 
.p-buttonset.p-button-secondary > .p-button, 
.p-splitbutton.p-button-secondary > .p-button, 
.p-fileupload-choose.p-button-secondary {
  background: #666;
}

.p-button.p-button-secondary:hover, 
.p-button.p-button-secondary:enabled:hover, 
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover, 
.p-buttonset.p-button-secondary > .p-button:enabled:hover, 
.p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, 
.p-splitbutton.p-button-secondary > .p-button:enabled:hover, 
.p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, 
.p-fileupload-choose.p-button-secondary:enabled:hover, 
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
  background: rgb(66 66 66 / 92%);
}

/*Global edit of datatable width for custom filter components*/
.p-column-filter {
  width: 100%;
}

.p-chips-multiple-container {
  width: 100%;
}

.p-tooltip {
  pointer-events: none;
}

/*Global edit of password width*/
.p-inputtext.p-password-input{
  width: 100%;
}

/* .imageUploader{
  width: 100%;
} */

#scopes p, #scopes label {
  text-transform: capitalize;
}

.field > label {
  font-weight: 600;
} 