.chatbot :global .p-card .p-card-footer {
  padding: 0 !important;
}

.chatbot :global .p-card .p-card-content {
  padding: 0 !important;
}

.chatbot :global .p-card .p-card-body {
  padding-top: 0;
}